import React from 'react';

export default function Projects() {
  
  return (
    <div className="Projects" style={{}}>
      <h1>Projects</h1>
    </div>
  );
}
