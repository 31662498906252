import React from 'react';

export default function About() {


  return (
    <div className="About">
      <h1>About</h1>
      <div style={{}}></div>
    </div>
  );
}
